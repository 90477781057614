<template>
  <v-card class="param" style="height: 360px;">
    <v-card-title class="py-1">
      <h4 class="title-aboutus">
        <v-icon size="16" class="ml-3 mr-2">fa-solid fa-circle-info</v-icon>
        {{ $t("views.about.about") }}
      </h4>
      <v-spacer />
      <v-btn depressed text color="white" @click.stop="$emit('closeForm')">
        <v-icon small class="mr-1">fa-regular fa-rectangle-xmark</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="ma-0 px-0 pt-2">
      <v-container >
        <v-row>
          <v-col cols="12">
            <div class="center">
              <LogoModule :dark="true" width="70" class="float-left" />
              <h2 color="primary" class="float-left title-logo">
                ASIGNACIÓN <br >
                DE SERVICIOS
              </h2>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0 mb-0 pb-0">
            <div class="center">
              <p class="infor">{{$t('views.about.version')}}<br> {{ version }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <div class="center">
              <p class="infor">
                <b>{{$t('views.about.developers')}}</b>
              </p>
            </div>
          </v-col>
        </v-row>
        <div class="center mb-5">
          <Logo width="150" class="float-left" />
        </div>
        <v-row>
          <v-col cols="12" class="pt-0">
            <div class="center">
              <p class="infor">© 2022, {{$t('views.about.copyright')}}</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { loadMessages } from "@/plugins/i18n";

export default {
  data() {
    return {
      selectLang: "es",
      lang: [
        {
          lang: "es",
          desc: "Español",
        },
        {
          lang: "en",
          desc: "Ingles",
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: this.$t("views.about.about"),
    };
  },
  computed: {
    ...mapGetters({
      locale: "lang/locale",
      locales: "lang/locales",
    }),
    version() {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    setLocale() {
      let locale = this.selectLang;
      if (this.$i18n.locale !== this.selectLang) {
        loadMessages(this.selectLang);
        this.$store.dispatch("lang/setLocale", { locale });
      }
    },
  },
};
</script>

<style scoped>
.param {
  height: auto;
}

.param .v-card__title {
  background-color: #0b0045 !important;
}

.title-aboutus,
.title-aboutus :deep(.fa-solid) {
  color: #fff !important;
  font-size: 14px;
}

.float-left {
  float: left;
}

.title-logo {
  margin-top: 0.5em;
  margin-left: 0.5em;
  font-style: italic;
  color: #0b0045;
}

.center {
  margin: 0 auto;
  display: table;
}

.infor {
  text-align: center;
  font-size: 1em;
  color: #0b0045;
}

</style>
